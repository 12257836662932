<template>
   <div>续费记录</div>
</template>

<script>
export default {
  name: "takeRecharge"
}
</script>

<style scoped>

</style>